<template>
  <div 
    v-if="scenario" 
    class="content"
    :style="{backgroundImage: `url(/maps/${scenario.mapImage})`}"
  >
    <main role="main">
      <div
        class="content-overlay brand-font-bold"
        :class="{hidden: !showText}"
      >
        <div class="content-overlay__scenario">
          <div
            class="content-overlay__description"
            v-html="scenario.completeText"
          />
        </div>
    
        <div class="content-overlay__question">
          <div class="content-overlay__question-actions content-overlay__question-actions-complete">
            <router-link :to="`/score`" role="button">
              <span>See your score</span>
              <ArrowRight />
            </router-link>
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style="position:absolute;top:0;left:0; width:100%; height: 100%;"
        :viewBox="scenario.viewBox"
      >
        <title>Vehicle journey</title>
  
        <!-- If there is a path given then use that -->
        <g v-if="scenario.path">
          <path
            id="animatePath"
            :d="scenario.path"
            fill="none"
            stroke="#fec000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="25"
            stroke-dasharray=".3 40"
          />
        </g>
    
        <!-- If there is not a path given then generate a straight path between the points -->
        <g v-if="!scenario.path">
          <path
            id="animatePath"
            :d="`
          M ${pathLocation.start.left}, ${pathLocation.start.top}
          L ${pathLocation.end.left}, ${pathLocation.end.top}`"
            fill="none"
            stroke="#fec000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="21"
            stroke-dasharray="0.42 33"
          />
        </g>
    
        <foreignObject
          width="100%"
          height="100%"
          :x="scenario.iconOffset ? scenario.iconOffset.x : null"
          :y="scenario.iconOffset ? scenario.iconOffset.y : null"
          :style="scenario.iconOffset ? `transform: scale(${scenario.iconOffset.scale}); overflow: visible;` : null"
        >
      
          <Incident
            class="incident-icon"
            :style="{top: scenario.incidentLocation.top, left: scenario.incidentLocation.left}"
          />
          <Hospital
            class="hospital-icon"
            :style="{top: hospitalLocation.top, left: hospitalLocation.left}"
          />
        </foreignObject>
    
        <image
          id="vehicle"
          :xlink:href="`/icons/${scenario.transport === 'ambulance' ? 'ambulance-image@2x.png' : 'helicopter-image@2x.png'}`"
          y="-130"
          x="-130"
          width="260px"
          height="260px"
          :style="scenario.iconOffset ? `transform: scale(${scenario.iconOffset.vehicleScale}) ${scenario.iconReverse ? `scaleX(-1)` : null};` : (scenario.iconReverse ? `transform:scaleX(-1)` : null)"
          alt="Image of a large ambulance"
        >
          <animateMotion
            id="vehicleAnimation"
            :dur="scenario.animationSpeed"
            repeatCount="1"
            fill="freeze"
            :keyPoints="scenario.pathReverse ? '1;0' : null"
            :keyTimes="scenario.pathReverse ? '0;1' : null"
            calcMode="linear"
          >
            <mpath xlink:href="#animatePath" />
          </animateMotion>
        </image>
      </svg>
    </main>
    <nav class="page-navigation" role="navigation">
      <div>
        <router-link 
          v-if="backLink" 
          :to="backLink"
        >
          <div>
            <ArrowLeft />
            <div>Back</div>
          </div>
        </router-link>
      </div>

      <div>
        <router-link to="/score">
          <div>
            <ArrowRight />
            <div>Next</div>
          </div>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
// @ is an alias to /src
import ArrowRight from '@/components/icons/ArrowRight.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import Hospital from '@/components/icons/Hospital.vue'
import Incident from '@/components/icons/Incident.vue'

export default {
  name: 'Complete',
  components: {
    ArrowRight,
    ArrowLeft,
    Hospital,
    Incident,
  },
  data() {
    // Configure the defaults for the data
    // scenario: the current scenario
    // showText: show the text content overlay
    return {
        scenario: null,
        showText: false
    } 
  },
  computed: {
    backLink() {
      return this.scenario && `/scenario/${this.$route.params.id}/question/${this.scenario.questions.length}`
    },
    hospitalLocation() {
      // Return the hospital from the array that is marked as correct
      return this.scenario.hospitalLocations.find(obj => {
        return obj.correct === true
      })
    },
    pathLocation() {
      // Calculate the start/end points for the path
      
      // Re-assign so we can manipulate without reference
      const start = Object.assign({}, this.scenario.incidentLocation);
      const end = Object.assign({}, this.hospitalLocation);

      // Width of the vehicle icon, used to centre adjust
      const width = 260

      // Offset the paths by the icon adjustments
      start.top += width / 2;
      start.left += width / 2;
      end.top += width / 2;
      end.left += width / 2;

      // Return the locations
      return {
        start: start,
        end: end
      }
    }
  },
  updated() {
    // Add the event listener for animation end, mounted is too early so we use updated
    document.getElementById('vehicleAnimation').addEventListener('endEvent', this.animationEnd);
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.id) {
          this.fetchData()
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  methods: {
    animationEnd() {
      // On animation end, remove event listener and show the text overlay
      this.showText = true;
      document.getElementById('vehicleAnimation').removeEventListener('endEvent', this.animationEnd);
    },
    async fetchData() {
      // Reset the scenario data
      this.scenario = null

      // Import the scenario data from the JSON
      await import('@/content/scenarios/scenario-' + this.$route.params.id + '.json').then((content) => {
        this.scenario = content.default
      }).catch(() => {
        // If it fails redirect to the home page
        this.$router.push('/')
      })
    }
  }
}
</script>

<style scoped>
.content {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hospital-icon, .response-icon, .incident-icon {
  position: absolute;
  width: 230px;
  height: auto;
}

.content-overlay {
  font-size: 70px;
  width: 50%;
  z-index: 999;
  position: relative;
  top: 3em;
  left: 0;
  transition: .35s ease all;
}

.content-overlay.hidden {
  left: -50%;
}

.content-overlay__question {
  background-color: #FEC000;
  border-radius: 0 0 1.2em 0;
  padding: 1.1em;
}

.content-overlay__question-actions a {
  color: #304251;
  font-size: 81px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.content-overlay__question-actions a svg {
  width: 1.2em;
  height: auto;
}

.content-overlay__scenario {
  background-color: #E1001E;
  padding: 1.1em;
  border-radius: 0 1.2em 0 0;
}

.page-navigation {
  position: absolute;
  z-index: 777;
  top: 70%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page-navigation > div {
  overflow:  hidden;
}

.page-navigation > div:first-of-type a {
  right: auto;
  left: -50%;
  text-align: right;
}

.page-navigation > div:first-of-type a svg {
  right: 15px;
  left: auto;
}

.page-navigation a {
  background: #FEC000;
  height: 350px;
  width: 350px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  right: -50%;
  text-decoration: none;
}

.page-navigation a > div {
  font-size: 50px;
  color: #E1001E;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  left: .75em;
  text-transform: uppercase;
}

.page-navigation > div:first-of-type a > div {
  right: .75em;
  left:  auto;
}

.page-navigation a > div > div {
  margin-top: .25em;
}

.page-navigation a svg {
  width: 1.75em;
  height: auto;
  position: relative;
  left: .25em;
}
</style>

<style>
.content-overlay__question-actions.content-overlay__question-actions-complete a svg * {
    fill: #304251;
}
</style>
